import React from "react"
import { Form, Field, Submit, validators } from "react-connect-form"
import styled, { keyframes } from "styled-components"
import * as Icon from "react-feather"
import Button from "../ui/Button"
import LocationField from "../form/LocationField"
import { CTA } from "../content/labels"

const FormWrap = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .geosuggest {
    margin-top: 0px;
  }

  @media (max-width: 800px) {
    align-items: center;
  }
`

const blink = keyframes`
	0% {
		opacity: 0;
	}
	40% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
`

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const IconContainer = styled.div`
  color: ${p => p.color};
  //animation: ${blink} 2s linear infinite;
`

const CreatePosterForm = ({
  onSubmit,
  location,
  foregroundColor,
  backgroundColor,
  buttonLabelStyle
}) => {
  const handleSubmit = data => {
    onSubmit(data)
  }

  return (
    <div>
      <Form
        style={{
          zIndex: 10
        }}
        onSubmit={handleSubmit}
        onChange={handleSubmit}
      >
        <FormWrap>
          <SearchContainer>
            {/* <IconContainer color={foregroundColor}>
              <Icon.ChevronRight />
            </IconContainer> */}
            <Field
              style={{ width: "90%", marginTop: 0 }}
              inputStyles={{
                paddingLeft: 10,
                height: 50,
                borderRadius: 8,
                border: `2px solid ${foregroundColor}`
              }}
              name="location"
              type="text"
              placeholder={"Enter any location"}
              component={LocationField}
              inputColor={foregroundColor}
              borderColor={foregroundColor}
              backgroundColor={backgroundColor}
            />
            <Submit
              component={buttonProps => {
                return (
                  <Button
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: 25,
                      marginLeft: 10,
                      padding: 0,
                      fontWeight: "bold"
                    }}
                    foregroundColor={backgroundColor}
                    backgroundColor={foregroundColor}
                    onClick={buttonProps.onSubmit}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: 15,
                        padding: "0px 20px",
                        fontFamily: "Gotham Pro",
                        fontWeight: "bold",
                        color: backgroundColor
                      }}
                    >
                      {/* <Icon.Edit
                        color={backgroundColor}
                        size={20}
                        style={{ marginRight: 4 }}
                      /> */}
                      Go
                    </div>
                  </Button>
                )
              }}
            />
          </SearchContainer>
        </FormWrap>
      </Form>
    </div>
  )
}

export default CreatePosterForm

import React from "react"
import styled from "styled-components"
import * as Icon from "react-feather"
import { Tooltip } from "@material-ui/core"

const MessageContainer = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  padding: 0px 10px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  background-color: ${p => p.backgroundColor || p.theme.palette.text.primary};
  color: ${p => p.foregroundColor || p.theme.palette.background.paper};
  border-bottom: 1px solid ${p => p.backgroundColor || p.theme.palette.divider};
`

const Message = ({ foregroundColor, backgroundColor }) => {
  return null

  return (
    <MessageContainer
      foregroundColor={foregroundColor}
      backgroundColor={backgroundColor}
    >
      <Tooltip title="Estimate based USA domestic standard shipping">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon.Gift size={18} style={{ marginRight: 10 }} />
          <span>Order by December 15th to be on time for Christmas!*</span>
        </div>
      </Tooltip>
    </MessageContainer>
  )
}

export default Message

import React from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import { track } from "../../utils/track"

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  margin: 0px;
  padding: 10px;
  text-align: center;
  z-index: 1;
  color: ${p => p.foregroundColor};
  background-color: ${p => p.backgroundColor};
  a {
    color: ${p => p.foregroundColor};
  }
`
const GVL = {
  location: {
    lng: -82.39401040001405,
    lat: 34.85261759997671
  },
  textTop: "Greenville SC",
  textBottom: "34.853°S / -82.394°W",
  showBorder: true,
  showSecondaryRoads: true,
  zoom: 11.622511482364311,
  backgroundColor: "#226f03",
  foregroundColor: "#fcfcfc",
  icon: "pin",
  textLayout: "circle",
  textStyle: "sans",
  showIntro: false,
  locationLabel: "Greenville, SC, United States",
  label: "",
  title: "",
  showPin: false,
  lng: -82.39401040000001,
  lat: 34.85261759999999,
  theme: ["#0C1622", "#fff2a7"]
}

const AppFooter = props => {
  const handleGreenville = e => {
    e.preventDefault()
    track("clicked-greenville")
    props.context.setPosterData(GVL)
    navigate("/editor")
  }
  return (
    <FooterContainer {...props}>
      <p>
        © Copyright TiltMaps {new Date().getFullYear()}. Designed and built by{" "}
        <a href="https://twitter.com/wmdmark">@wmdmark</a> in{" "}
        <a href={"#"} onClick={handleGreenville}>
          Greenville SC
        </a>
        {" | "}
        <Link to="/privacy-policy">Privacy Policy</Link>
        {" | "}
        <Link to="/terms-of-service">Terms of Service</Link>
      </p>
    </FooterContainer>
  )
}

export default AppFooter

import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import color from "color"
import Button from "../ui/Button"
import Wrap from "../ui/Wrap"
import MapBG from "../ui/MapBG"
import AppBar from "../ui/AppBar"
import Message from "../ui/Message"
import Stripe from "../ui/Stripe"
import AppFooter from "../ui/AppFooter"
import SkewCard from "../ui/SkewCard"
import Testimonials from "./Testimonials"
import Features from "./Features"
import FAQs from "./FAQs"
import Headline from "./Headline"
import CreatePosterForm from "./CreatePosterForm"
import { getLocationDataFromGoogle } from "../utils/location"
import { track } from "../../utils/track"
import { coreFAQs } from "../content/faqs"
import { CTA } from "../content/labels"
import Poster from "../poster/Poster"
import { baseSize } from "../poster/data"
import usePoster from "../../hooks/usePoster"
import { examplePosters } from "./data"
import { useWindowSize } from "../../hooks/useWindowSize"
import { getScaleDown } from "../poster/utils"

const MOBILE_THRESHOLD = 800

const Container = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0px auto;
  position: relative;
`

const PageContainer = styled(Container)`
  padding: 0px 0px;
  padding-top: 0px;
  z-index: 10;
  display: flex;
  font-size: 16px;
  align-items: center;
  @media (max-width: ${MOBILE_THRESHOLD}px) {
    flex-direction: column;
    justify-content: center;
  }
`

const Header = styled.header`
  color: ${p => p.color};
  text-align: left;
`

const BottomPanel = styled.div`
  background-color: ${p => p.backgroundColor};
  width: 100%;
  height: 100%;
`

const PitchContainer = styled.div`
  text-align: center;
  width: 50%;
  padding: 20px;
  position: relative;
  z-index: 2;

  @media (max-width: 900px) {
    padding: 10px;
    width: 100%;
  }
`

const Intro = () => {
  const [posterState, updatePoster] = usePoster()
  const { width: containerWidth } = useWindowSize()
  const coordinates = posterState.location
  const previewSize = Math.min(containerWidth - 40, 450)
  const mobile = containerWidth < MOBILE_THRESHOLD

  let { foregroundColor, backgroundColor } = posterState
  const bg = color(backgroundColor)
  backgroundColor = bg
    .saturate(-0.3)
    .darken(0.3)
    .rgb()
    .string()
  const trueBGColor = bg.string()

  const buttonLabelStyle = {
    fontSize: 15,
    fontFamily: "Gotham Pro",
    fontWeight: "bold"
  }

  const posterHeight = baseSize * (posterState.size === "18x18" ? 1 : 24 / 18)
  const maxSize = !mobile ? baseSize * (24 / 18) + 40 : posterHeight

  const handleSelectExample = selectedExample => {
    updatePoster(selectedExample)
    track("show-example", selectedExample)
  }

  const handleSubmitLocation = result => {
    if (result.location) {
      const locationData = getLocationDataFromGoogle(result)
      const nextState = { ...posterState, ...locationData }
      track("show-editor-via-location")
      updatePoster(nextState)
      navigate(`/editor`)
    } else {
      handleShowEditor()
    }
  }

  const handleShowEditor = () => {
    track("show-editor")
    navigate("/editor")
  }

  return (
    <Wrap
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      style={{ paddingBottom: 140, zIndex: 1 }}
    >
      {/* <Lights
        width={containerWidth}
        backgroundColor={trueBGColor}
        foregroundColor={foregroundColor}
      /> */}
      <Message
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      />
      <AppBar
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        mobile={mobile}
        onSelectEdit={handleShowEditor}
      />

      <PageContainer frameColor={foregroundColor} mobile={mobile}>
        <PitchContainer>
          <Header color={foregroundColor}>
            <Headline color={foregroundColor} />
          </Header>

          <CreatePosterForm
            buttonLabelStyle={buttonLabelStyle}
            foregroundColor={foregroundColor}
            backgroundColor={trueBGColor}
            onSubmit={handleSubmitLocation}
          />

          <div style={{ height: 40 }} />
          <SkewCard
            heading="Samples"
            backgroundColor={backgroundColor}
            color={foregroundColor}
            offsetX={0}
            offsetY={0}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap"
              }}
            >
              {examplePosters.map((example, i) => {
                return (
                  <Button
                    key={`example-${i}`}
                    foregroundColor={example.foregroundColor}
                    backgroundColor={example.backgroundColor}
                    onClick={() => {
                      handleSelectExample(example)
                    }}
                    style={{ marginRight: 6, marginBottom: 6 }}
                  >
                    {example.textTop}
                  </Button>
                )
              })}
            </div>
          </SkewCard>
        </PitchContainer>

        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: maxSize
          }}
        >
          <Poster
            width={baseSize}
            height={posterHeight}
            // scaleDown={baseSize > previewSize ? previewSize / baseSize : 1.0}
            scaleDown={getScaleDown(previewSize, posterState)}
            transitionIn={true}
            framed
            interactive={false}
            overlayImage={false}
            {...posterState}
            location={coordinates}
          />
        </div>
      </PageContainer>

      <Stripe
        borderColor={foregroundColor}
        backgroundColor={backgroundColor}
        rotation={-10}
        top={400}
        height="66%"
      />

      <BottomPanel borderColor={foregroundColor}>
        <Container>
          <Features
            foregroundColor={foregroundColor}
            backgroundColor={backgroundColor}
          />
          <SkewCard
            heading="Testimonials"
            backgroundColor={backgroundColor}
            color={foregroundColor}
            offsetX={0}
            offsetY={0}
            style={{ marginTop: 40 }}
          >
            <Testimonials {...{ foregroundColor, backgroundColor }} />
          </SkewCard>

          <SkewCard
            heading="Gifts"
            skew={mobile ? -2 : -6}
            backgroundColor={backgroundColor}
            color={foregroundColor}
            offsetX={0}
            offsetY={0}
            style={{ marginTop: 40, textAlign: "center" }}
          >
            <p>
              The best gifts aren’t just beautiful and high quality: They're
              also meaningful. A TiltMap you designed to beautifully showcase
              around an important place to someone you care for is a gift
              they’ll be proud to show.
            </p>
            <Button
              onClick={handleShowEditor}
              style={{ marginTop: 15, width: 200 }}
              backgroundColor={foregroundColor}
              foregroundColor={backgroundColor}
              labelStyle={{ fontSize: 15, fontFamily: "Gotham Pro" }}
              label={CTA}
            />
          </SkewCard>

          <SkewCard
            heading={"F.A.Q.s"}
            skew={mobile ? 2 : 6}
            backgroundColor={backgroundColor}
            color={foregroundColor}
            offsetX={0}
            offsetY={0}
            style={{ marginTop: 40 }}
          >
            <FAQs
              foregroundColor={foregroundColor}
              backgroundColor={backgroundColor}
              faqs={coreFAQs}
            />
          </SkewCard>
        </Container>
        <Stripe
          borderColor={foregroundColor}
          backgroundColor={backgroundColor}
          rotation={2}
          height={140}
          top={`calc(100% - ${140 / 2}px)`}
          left={-50}
        />
        <AppFooter />
      </BottomPanel>
      <MapBG
        backgroundColor={backgroundColor}
        width={containerWidth}
        height={700}
        posterState={posterState}
        location={coordinates}
      />
      {/* <MessengerChat
        pageId="1941844739434839"
        themeColor={"#000"}
        htmlRef="fb-chat"
      /> */}
    </Wrap>
  )
}

export default Intro
